
import * as aroraProviderRuntime$zRs5T4cfe2N0F21UnRLwIJxyD2sSHqzGG_45ka_451FN3Es from 'D:/deploy/UploadHidden/20250319-P903.292/arora-front/satellite/build-rest-9a413d0d-acd9-485b-af3f-79b984f4f04f/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$zRs5T4cfe2N0F21UnRLwIJxyD2sSHqzGG_45ka_451FN3Es, defaults: {} }
}
        